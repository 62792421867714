import { WrapRootElementNodeArgs, WrapRootElementBrowserArgs } from 'gatsby'

import './src/scss/index.scss'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'

export const wrapRootElement = ({ element }: WrapRootElementNodeArgs | WrapRootElementBrowserArgs) => {
  return element
}
